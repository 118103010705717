import React from "react";
import '../styles/footer.css'
import { Link } from "react-router-dom";

function Footer() {
    return(
        <div className="mt-5">
            <footer>
            <div className="container">
                <div class="row">

                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <ul class="menu">
                                <span>Menu</span>    
                                <li>
                                    <Link to={"/empresa"}><a>Empresa</a></Link>
                                </li>
                                        
                                <li>
                                    <Link to={"/categorias"}><a>Productos</a></Link>
                                </li>
                                        
                                <li>
                                    <Link to={"/industrias"}><a>Industrias</a></Link>
                                </li>
                                        
                                <li>
                                    <Link to={"/servicios"}><a>Servicios</a></Link>
                                </li>
                            </ul>
                        </div>
                    
                        <div class="col-md-6 col-sm-6 col-xs-12">
                        <ul class="address">
                                <span>Contacto</span>       
                                <li>
                                    <i class="fa fa-phone" aria-hidden="true"></i> <a href="/contacto">Tel/Fax: 5411 4773-1139</a>
                                </li>
                                <li>
                                    <i class="fa fa-map-marker" aria-hidden="true"></i> <a href="/contacto">Gurruchaga 1177 P.4</a>
                                </li> 
                                <li>
                                    <i class="fa fa-envelope" aria-hidden="true"></i> <a href="/contacto">ventas@termoar.com.ar</a>
                                </li> 
                            </ul>
                        </div>
                    
                    
                    </div> 
                </div>     
            </footer> 
            <div className="text-white text-center search-text" style={{marginTop:'0'}}>
                &copy; {new Date().getFullYear()} Copyright <b>Termometría Argentina.</b> 
            </div>
        </div>
    )
}
export default Footer