import { url } from '../constants/api'
export default class Productos{

    getProductoByIndustriaByCategoria(idIndustrias, idCategoria) {
        return fetch(`${url}/productos/industria/${idIndustrias}/categoria/${idCategoria}`, {
            method: 'GET'
        })
        .then(response => {return response.json()})
        .catch(error => console.log(error))
    }

    getProductoByCategoria(idCategoria) {
        return fetch(`${url}/productos/categoria/${idCategoria}`, {
            method: 'GET'
        })
        .then(response => {return response.json()})
        .catch(error => console.log(error))
    }

    getProducto(idProducto) {
        return fetch(`${url}/productos/${idProducto}`, {
            method: 'GET'
        })
        .then(response => {return response.json()})
        .catch(error => console.log(error))
    }

    getProductoBySubCategoriaYCategoria(idCategoria ,idSubcateogira) {
        return fetch(`${url}/productos/categoria/${idCategoria}/subcategoria/${idSubcateogira}`, {
            method: 'GET'
        })
        .then(response => {return response.json()})
        .catch(error => console.log(error))
    }

    getProductoByIndustriaByCategoriaBySubcategoria(idIndustria, idCategoria, idSubcategoria) {
         return fetch(`${url}/productos/industria/${idIndustria}/categoria/${idCategoria}/subcategoria/${idSubcategoria}`, {
            method: 'GET'
        })
        .then(response => {return response.json()})
        .catch(error => console.log(error))
    }
}