import React from "react";
import Navbar from "./Navbar";
import Footer from './Footer'
import '../styles/style.css'

function Layout({className ,children}) {
    return(
        <div >
            <div className="minheight">
                <Navbar />
                <div style={{height:'110px'}}></div>
                <div className={className}>{children}</div>
            </div>
            <Footer />
        </div>
    )
}
export default Layout