import React, { useEffect, useState } from 'react'
import Layout from '../layouts/Layout';
import SubCategoria from '../models/subcategoria'
import { Link } from 'react-router-dom'

const ProductDetail = ({producto}) => {
   const [categoria,setCategoria] =useState(null)

   const getSubCategoria = () => {
    const repo = new SubCategoria()
    repo.getSubcategoria(producto.subcategoria.id)
        .then(data => {
            setCategoria(data.categoria)
        })
}
    
useEffect(() => {
    getSubCategoria();
}, []);    return (
        
    <Layout>
         <div className="container">
           <div className="container mt-3">
             <div className="container">
               <div className="row-fluid">
               <nav aria-label="breadcrumb">
               <ol className="breadcrumb">
                  <li className="breadcrumb-item " aria-current="page"><Link to={`/categorias/${categoria && categoria.id}`}>{categoria && categoria.titulo}</Link></li>
                  <li className="breadcrumb-item " aria-current="page">{producto.subcategoria.nombre} </li>
                  <li className="breadcrumb-item " aria-current="page">{producto.titulo}</li>

               </ol>
               </nav>
               <div className="row container">
                  <div className="col-6">
                     <div className="row">
                        <div className=" h2">{producto.titulo}</div>
                     </div>
                     <div className="row">
                        <div className="">{producto.descripcion}</div>
                     </div>
                  </div>

                 <div className="ml-auto"><img src={ producto.marca.imagen.path} alt='title' width="200px" className="float-right" /> </div>
               </div>

               </div>
               <br/>
               <br/>
             </div>
             <div className="container row">
               <div className="col-md-4">
                 <div id="carousel-cat-1" data-ride="carousel" className="carousel slide">
                   <div className="carousel-inner" style={{border: '1px solid rgb(229, 229, 229)', padding: '15px'}}>
                     <div data-slide-number={0} className="carousel-item active" style={{backgroundSize: 'contain', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', backgroundImage: `url(${ producto.imagen.path})`}}>
                       <div className="card-image-container" style={{maxHeight:'400px'}} >
                       <img src={producto.imagen.path} alt="" class="card-img-top img-fluid" />
                       </div>
                     </div>
                   </div>
                   <ol className="carousel-indicators" style={{position: 'relative', marginRight: '0px', marginLeft: '0px'}}>
                     <li data-target="#carousel-cat-1" data-slide-to={0} className="active" style={{minHeight: '60px', maxHeight: '60px', minWidth: '50px'}}><img src={ producto.imagen.path} alt='' className="d-block img-fluid img-thumbnail" style={{minHeight: '60px', maxHeight: '60px', minWidth: '50px'}} /></li>
                   </ol>
                 </div>
                 <div className="row">
                    <a href={ producto.archivo && producto.archivo.path} target="blank" className="btn btn-primary" style={{width:"100%"}}>VER HOJA TECNICA</a>
                    <a href='/contacto' className="btn btn-info" style={{width:"100%"}}>CONSULTAR</a>
                 </div>
               </div>
               <div className="col-md-8 rounded border border-light">
                 <div className="ml-3 pr-3 pb-3">
                  <div className="row">
                    <p className=" h2 pt-3">
                      <span>Descripcion</span>
                    </p>
                  </div>
                  <div className="row" style={{whiteSpace: "break-spaces"}}>
                      {producto.descripcionLarga.replace("\"",'')}
                  </div>
                  </div>
                  </div>
             </div>
          </div>
      </div>
      </Layout>)
}
export default ProductDetail