import React, { useEffect, useState } from 'react'
import Layout from '../layouts/Layout';
import Categorias from '../models/categorias';
import CategoriaCard from '../components/CategoriaCard'
import Alert from '../components/Alert'

function CategoriaByIndustria(props){

    const [categorias, setCategorias] = useState([])
    const getCategoriasByIndustrias = (id) => {
        const repo = new Categorias()
        repo.getCategoriasByIndustria(id)
            .then(data => {
                setCategorias(data)
            }) 
    }
    
    useEffect(() => {
        const id = props.match.params.id
        getCategoriasByIndustrias(id)
    }, [props])

    return(
        <Layout>
            <div className="mx-auto container" >
                <div className="text-center h1">
                    <hr />
                    <h1>Categorias</h1>
                    <hr />
                </div>
                <div className="ml-3 mb-5" >
                    <p className="texto-gris">
                        Desde sus inicios Termometria Argentina nos planteamos el objetivo de interpretar cada
                        necesidad técnica y de presupuesto de nuestros clientes; ofreciendo con una amplia variedad 
                        de productos en las líneas en que nos especializamos. Contamos además con el personal idóneo para 
                        asesorar a los usuarios en la aplicación de cara producto y en su integración.
                    </p>
                </div>
            </div>
            <div className="container text-center">
                <div className="row">
                    {
                        categorias ? 
                        categorias.map( cat => (
                            <div key={cat.id} className="col-md-4 col-12 mb-3" style={{minHeight: "230px"}}>
                                <CategoriaCard categoria={cat} industria_id={props.match.params.id} />
                            </div>
                        )) :
                        <Alert mensaje={"No existen categorias para esta industria."} />
                    }
                </div>
            </div>
        </Layout>
    )
}

export default CategoriaByIndustria;