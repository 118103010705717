import { url } from '../constants/api'
export default class Marcas{

    getMarcas() {
        return fetch(`${url}/marcas`, {
            method: 'GET'
        })
        .then(response => {return response.json()})
        .catch(error => console.log(error))
    }

    getMarca(idMarca) {
        return fetch(`${url}/marcas/${idMarca}`, {
            method: 'GET'
        })
        .then(response => {return response.json()})
        .catch(error => console.log(error))
    }
}