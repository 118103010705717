import React, { useEffect, useState } from 'react'
import Layout from '../layouts/Layout';
import CategoriasModel from '../models/categorias';
import CategoriaCard from '../components/CategoriaCard'
import Alert from '../components/Alert'
import banner from '../static/images/banner.jpg'

function Categorias(props){

    const [categorias, setCategorias] = useState([])
    const getCategorias = () => {
        const repo = new CategoriasModel()
        repo.getCategorias()
            .then(data => {
                setCategorias(data)
            }) 
    }
    
    useEffect(() => {
        getCategorias()
    }, [props])

    return(
        <Layout>
            <img src={banner} className="card-img-top " />
            <div className="mx-auto container" >
                <div className="text-center h1">
                    <hr />
                    <h1>Categorias</h1>
                    <hr />
                </div>
                <div className="ml-3 mb-5" >
                    <p className="texto-gris">
                        Desde sus inicios en Termometria Argentina nos planteamos el objetivo de interpretar cada
                        necesidad técnica y de presupuesto de nuestros clientes; ofreciendo con una amplia variedad 
                        de productos en las líneas en que nos especializamos. Contamos además con el personal idóneo para 
                        asesorar a los usuarios en la aplicación de cara producto y en su integración.<br />
                        Comercializamos todas las líneas de las marcas que representamos. Consúltenos por cualquier producto que no encuentre en esta página.

                    </p>
                </div>
            </div>
            
            <div className="container text-center">
                <div className="row">
                    {
                        categorias ? 
                        categorias.map( cat => (
                            <div key={cat.id} className="col-md-4 col-12 mb-3" style={{minHeight: "230px"}}>
                                <CategoriaCard categoria={cat} industria_id={props.match.params.id} />
                            </div>
                        )) :
                        <Alert mensaje={"Estamos pasando por problemas tecnicos. Intente nuevamente mas tarde."} />
                    }
                </div>
            </div>
        </Layout>
    )
}

export default Categorias;