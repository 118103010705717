import { url } from '../constants/api'
export default class Industrias{

    #idIndutria = ''
    #titulo
    #imagen = ''
    industrias = []

    constructor(idIndustria = '', titulo = '', imagen = '') {
        this.#idIndutria = idIndustria
        this.#titulo = titulo
        this.#imagen = imagen
    }


    idIndustria() { return this.#idIndutria }
    titulo() { return this.#titulo }
    imagen() { return this.#imagen }
    industrias() { return this.industrias }


    getIndustrias() {
        return fetch(`${url}/industrias`, {
            method: 'GET'
        })
        .then(response => {return response.json()})
        .catch(error => console.log(error))
    }
}