import React from 'react'
import { Link } from 'react-router-dom'
import '../styles/categoriaCard.css'

const CategoriaCard = ({categoria, industria_id=''}) => {

    return (
        
        <Link to={
            industria_id ?
            `/productos/industria/${industria_id}/categoria/${categoria.id}`
            :
            `/categorias/${categoria.id}`
            }>
                <div className="card h-100">
                <img className="mx-auto mt-5" style={{width:"min-content"}} src={categoria && categoria.imagen && categoria.imagen.path} alt={categoria.titulo}  />
                <div className="card-body">
                    <h3 className="card-title h3"> {categoria && categoria.titulo} </h3>
                    <p className="card-text" style={{textAlign:'center'}}> {categoria && ( categoria.descripcion.length>50 ? categoria.descripcion.substring(0,47)+"...":categoria.descripcion)}</p>
                </div>
            </div>
        </Link>
    )
}

export default CategoriaCard