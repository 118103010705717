import React from "react";
import Slider from "react-slick";

function MultiCarousel({imagenes,settings}) {

  

    return (
        <div className="mb-5">
            <Slider {...settings}>
                { imagenes && imagenes.map( i => (
                    <div>
                        <img src={i.imagen} alt='' width={200} height={100} style={{margin: '0 auto'}}/>
                        
                            {
                                i.texto != null? <p>{i.texto}</p> :<></>
                            }
                    </div>
                )) }
            </Slider>
        </div>
    )
}

export default MultiCarousel