import React from "react";
import {Link, withRouter} from "react-router-dom";

import logo from '../static/images/termoar-logo.png'
import '../styles/navbar.css'

const isActive = (history, path) => {
    if (history.location.pathname === path) {
        return {
            color: "#6ec1e3",
            borderBottom: '4px solid dodgerblue',
            fontWeight: "600"};

        }
        return{ color:"black" }
};

const Navbar = ({history}) => (
        <nav className="navbar navbar-expand-lg navbar-light bg-white fixed-top nav navbar-center" >
            <div className="container">
                <div className="row" style={{width:"100%"}}>
                    <Link to="/" className="ml-4" >
                        <img src={logo} alt="" height={70} width={180} className="logo--navbar"/>
                    </Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse navbar-items" id="navbarTogglerDemo03" >                    
                        <ul className="navbar-nav ml-auto ul--navbar" style={{fontWeight: "400"}}>
                            <li className="nav-item mr-3">
                                <Link className="nav-link" to="/empresa" style={isActive(history, "/empresa")}><p className="nav-p">Empresa</p></Link>
                            </li>
                            <li className="nav-item mr-3 " >
                                <Link className="nav-link" style={isActive(history, "/categorias")} to="/categorias"><p className="nav-p">Productos</p></Link>
                            </li>
                            <li className="nav-item mr-3 ">
                                <Link className="nav-link" style={isActive(history, "/industrias")} to="/industrias"><p className="nav-p">Industrias</p></Link>
                            </li>
                            <li className="nav-item mr-3 ">
                                <Link className="nav-link" style={isActive(history, "/servicios")} to="/servicios"><p className="nav-p">Servicios</p></Link>
                            </li>
                            <li className="nav-item mr-3 ">
                                <Link className="nav-link" style={isActive(history, "/contacto")} to="/contacto"><p className="nav-p">Contacto</p></Link>
                            </li>
                        </ul> 
                    </div>
                </div>
            </div>
        </nav>
    
);

export default withRouter(Navbar);