import * as React from "react";
import { Admin, Resource } from 'react-admin';

import dataProvider from './dataProvider'
import authProvider from './authProvider'

import { ProductoList, ProductoEdit, ProductoCreate } from './Productos'
import { IndustriaList, IndustriaEdit, IndustriaCreate } from './Industrias'
import { CategoriaList, CategoriaEdit, CategoriaCreate } from './Categorias'
import { MarcaList, MarcaEdit, MarcaCreate } from './Marcas'
import { SubCategoriaList, SubCategoriaEdit, SubCategoriaCreate } from './SubCategorias'

const AdminComponent = (props) => {

  return (
    <Admin authProvider={authProvider} dataProvider={dataProvider}>
      <Resource name="productos" list={ProductoList} edit={ProductoEdit} create={ProductoCreate}/> 
      <Resource name="industrias" list={IndustriaList} edit={IndustriaEdit} create={IndustriaCreate}/>
      <Resource name="categorias" list={CategoriaList} edit={CategoriaEdit} create={CategoriaCreate}/>
      <Resource name="marcas" list={MarcaList} edit={MarcaEdit} create={MarcaCreate}/>
      <Resource name="subcategorias" list={SubCategoriaList} edit={SubCategoriaEdit} create={SubCategoriaCreate} />
    </Admin>
  )
}

export default AdminComponent