import React, { useEffect, useState } from "react";
import Layout from '../layouts/Layout'
import banner from '../static/images/termoar-logo.png'

function Contacto() {

    const [nombre, setNombre] = useState("")
    const [telefono, setTelefono] = useState("")
    const [email, setEmail] = useState([])
    const [consulta, setConsulta] = useState("")
    

   const handleTag = () => {

    window.location.replace("mailto:ventas@termoar.com.ar?subject=Consulta Termometria&body=Nombre: "+nombre+"%0D%0AEmail: "+email+"%0D%0ATelefono: "+telefono+"%0D%0AConsulta: "+consulta)
   }

    return(
        <Layout>
            <div className="container mt-3">
                    <div className="text-center h1">
                        <hr />
                        <h1>Contacto</h1>
                        <hr />
                    </div>
                    <div className="row" >
                        <div className="col text-white text-center" style={{backgroundColor: "#6ec1e4"}}>
                            <h1 className="pr-3 pt-5">Nuestras Oficinas</h1>
                            <br />
                            <p className="pr-3" style={{fontSize:"20px"}}>Buenos Aires, Argentina</p>
                            <br />

                            <p className="pr-3" style={{fontSize:"20px"}}>Gurruchaga 1177 P.4</p>
                        </div>
                        <div className="col pt-5 text-center">
                            <i className="fa fa-envelope mr-3" style={{display:"inline-block",fontSize:"50px"}}></i>
                            <br />
                            <p style={{fontSize:"30px"}}>ventas@termoar.com.ar</p>
                            <p style={{fontSize:"30px"}}>5411 4773-1139</p>
                        </div>
                    </div>
                    <div>
                    <br />
                    <hr />
                    <br/>
                    </div>
                        <h4 className="text-center">Envíenos su consulta. Nos contactaremos a la brevedad</h4><br />
                    <div className="row ">

                        <div className='form' style={{width:"100%"}} >
                            <div className="row">
                                <div className="col">
                                    <div className='form-group'>
                                        <input type='text' name='Nombre' id="nombre" className='form-control' onChange={(e) => setNombre(e.target.value)}  placeholder="Nombre"></input>
                                    </div>
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col">
                                    <div className='form-group'>
                                        <input type='text' name='Email' className='form-control' onChange={(e) => setEmail(e.target.value)}  placeholder="Email"></input>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className='form-group'>
                                        <input type='text' name='Telefono' className='form-control' onChange={(e) => setTelefono(e.target.value)}  placeholder="Telefono"></input>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <textarea name='Consulta' className='form-control' onChange={(e) => setConsulta(e.target.value)}  placeholder="Escriba un mensaje"></textarea>
                                </div>
                            </div>
                            <br/>
                            <br/>
                            <input  type="submit" value="enviar" id="submitContact" onClick={()=> handleTag()}  className="text-uppercase float-right btn btn-block btn-primary"/>
                            <br/>
                            <br/>
                        </div>
                    </div>

                    <br />
                    <hr />
                    <br />

                        <div className="mapouter mx-auto"><div className="gmap_canvas"><iframe width="1080" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=termometria%20argentina&t=&z=17&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://putlocker-is.org"></a></div></div>

            </div>
        </Layout>
    )
}

export default Contacto