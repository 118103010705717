import React from 'react'
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom'
import Home from "./views/Home";
import IndustriasList from "./views/Industrias";
import Producto from "./views/Productos";
import CategoriaByIndustria from './views/CategoriaByIndustria'
import Productos from './views/Productos'
import ProductoView from './views/unProducto'
import Categorias from './views/Categorias'
import Contacto from './views/Contacto'
import Empresa from './views/Empresa'
import Servicios from './views/Servicios'
import Page404 from './views/404'

import AdminComponent from "./admin/Admin"

function  Routes() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={Home}/>
                <Route path="/empresa" exact component={Empresa}/>
                <Route path="/servicios" exact component={Servicios}/>
                <Route path="/industrias" exact component={IndustriasList}/>
                <Route path="/admin" exact component={AdminComponent} />
                <Route path="/producto" exact component={Producto} />
                <Route path="/contacto" exact component={Contacto} />
                <Route path="/categorias" exact component={Categorias} />
                <Route path="/categorias/:idCategoria" exact component={Productos} />
                <Route path="/categorias/industria/:id" exact component={CategoriaByIndustria} />
                <Route path="/productos/industria/:idIndustria/categoria/:idCategoria" exact component={Productos} />
                <Route path="/producto/:idProducto" exact component={ProductoView} />
                <Route path="/404" exact component={Page404} />

                <Route >
                    <Redirect to="/404" />
                </Route> 

                {/* <Route path="/industrias/:id" exact component={Industria} /> */}

            </Switch>
        </BrowserRouter>
    )
}

export default Routes