import React from 'react'
import { Link } from 'react-router-dom'
import '../styles/industriaCard.css'

const ProductoCard = ({producto}) => {

    return (
        <Link to={`/producto/${producto.id}`}>
            <div className="card h-100">
                <img className="card-img-top img-fluid" src={producto && producto.imagen && producto.imagen.path} alt={producto.titulo} height={200} width={300}/>
                <div className="card-body" />
                    <h3 className="card-title h3"> {producto && producto.titulo} </h3>
                    <p className="card-text text-dark" style={{textAlign:'center'}}> {producto &&( producto.descripcion.length>50 ? producto.descripcion.substring(0,47)+"...":producto.descripcion)}</p>

                <div/>
            </div>
        </Link>
    )
}

export default ProductoCard