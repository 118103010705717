import React, { useEffect, useState } from "react";
import Layout from '../layouts/Layout'
import "../styles/page404.css"
import {Link} from "react-router-dom";
function Page404() {



    return(
<div>
{/* 
		  <script src="https://oss.maxcdn.com/html5shiv/3.7.3/html5shiv.min.js"></script>
		  <script src="https://oss.maxcdn.com/respond/1.4.2/respond.min.js"></script> */}

	<div id="notfound">
		<div className="notfound">
			<div className="notfound-404">
				<h1>4<span>0</span>4</h1>
			</div>
			<h2>Perdon! No encontramos la pagina que buscas</h2>
            <button className="btn" style={{background: "#03b7ff"}}>
            <Link to="/" className="nav-link text-white" > Volver al inicio </Link>
            </button>
            
		</div>
	</div>
</div>
    )
}
export default Page404