import React, { useEffect, useState } from 'react'
import Layout from '../layouts/Layout';
import ProductosModel from '../models/productos';
import SubCategoriasModel from '../models/subcategoria';
import ProductoCard from '../components/ProductoCard'
import Alert from '../components/Alert'
import banner3 from '../static/images/banner3.jpg'
function Productos(props){

    const [productos, setProductos] = useState([])
    const [subcategorias, setSubcategorias] = useState([])
    const [subcategoria, setSubcategoria] = useState('')

    const getProductoByIndustriaByCategoria = (idIndustria, idCategoria) => {
        const repo = new ProductosModel()
        repo.getProductoByIndustriaByCategoria(idIndustria, idCategoria)
            .then(data => {
                setProductos(data)
            }) 
    }
    
    const getProductosByCategoria = (idCategoria) => {
        const repo = new ProductosModel()
        repo.getProductoByCategoria(idCategoria)
            .then(data => {
                setProductos(data)
            })
    }

    const getProductosBySubCategoriaYCategoria = (idCategoria ,subcategoria) => {
        const repo = new ProductosModel()
        repo.getProductoBySubCategoriaYCategoria(idCategoria,subcategoria)
            .then(data => {
                setProductos(data)
            })
    }

    const getSubcategorias = (idCategoria) => {
        const repo = new SubCategoriasModel()
        repo.getSubcategoriasByCategoria(idCategoria)
            .then(data => {
                setSubcategorias(data)
            })
    }

    const getProductoByIndustriaByCategoriaBySubcategoria = (idIndustria, idCategoria, subcategoria) => {
        const repo = new ProductosModel()
        repo.getProductoByIndustriaByCategoriaBySubcategoria(idIndustria, idCategoria, subcategoria)
            .then(data => {
                setProductos(data)
            })
    }

    const filterSubcategoria = () => {
        return(
            <select className='form-control mb-5' onChange={handleChange} style={{textAlignLast:'center'}}>
                <option value=''>--- Seleccione Una --- </option>
                {subcategorias && subcategorias.map(s => (
                    <option value={s.id}>{s.nombre}</option>
                ))}
            </select>
        )
    }

    const handleChange = e => {
        setSubcategoria(e.target.value)
    }
    
    useEffect(() => {
        const idIndustria = props.match.params.idIndustria
        const idCategoria = props.match.params.idCategoria
        getSubcategorias(idCategoria)
        if(idIndustria){
            if(subcategoria === ''){
                getProductoByIndustriaByCategoria(idIndustria, idCategoria)
            } else {
                getProductoByIndustriaByCategoriaBySubcategoria(idIndustria, idCategoria, subcategoria)
            }
        }
        else {
            if(subcategoria === ''){
                getProductosByCategoria(idCategoria)
            } else {
                getProductosBySubCategoriaYCategoria(idCategoria,subcategoria)
            }
        }
    }, [props, subcategoria])

    return(
        <Layout>
                <img src={banner3} className="card-img-top " />
                <div className="text-center h1 container">
                        <hr />
                        <h1>Selector de Productos</h1>
                        <hr />
                    </div>
                <div className="row mx-auto container" >
                    {filterSubcategoria()}
                </div>
                
                <div className="container text-center">
                <div className="row">
                    {
                        productos.length>0?
                        productos.map((prod) => (
                            <div key={prod.id} className="col-md-3 col-12 mb-3">
                                <ProductoCard producto={prod} />
                            </div>
                        )) :
                        <Alert mensaje={"No existen productos para esta subcategoria"} />
                    }
                    </div>
                </div>
        </Layout>
    )
}

export default Productos;