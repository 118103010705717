import * as React from 'react'
import { 
    List, 
    Datagrid, 
    TextField, 
    EditButton, 
    Edit, 
    SimpleForm,
    TextInput,
    Create,
    Filter,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    ImageField,
    ImageInput,
    ReferenceManyField,
    FileField,
    FileInput,
    SingleFieldList,
    ChipField,
    ArrayInput,
    SimpleFormIterator
} from 'react-admin'



const ProductoFilter = props => (
    <Filter {...props} >
        <TextInput label="Search by titulo" source="titulo" alwaysOn />
    </Filter>
)

export const ProductoList =  props => (
    <List filters={<ProductoFilter />} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="titulo" />
            <TextField source="descripcion" />
            <ReferenceField label="Sub categoria" source="subcategoria.id" reference="subcategorias">
                <TextField source="nombre" />
            </ReferenceField>
             {/* <ReferenceManyField label="Industria"  source="industria">
                <SingleFieldList>
                    <ChipField source="titulo" />
                </SingleFieldList>
            </ReferenceManyField> */}
            <ReferenceField label="Marca" source="marca.id" reference="marcas">
                <TextField source="nombre" />
            </ReferenceField>
            <EditButton />
        </Datagrid>
    </List>
)

const ProductoTitle = ({ record }) => {
    return <span>
        {record ? `${record.titulo}` : ''}
    </span>
}

export const ProductoEdit = props => (
    <Edit title={<ProductoTitle />} {...props} undoable={false}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <TextInput source="titulo" />
            <TextInput source="descripcion" />
            <TextInput multiline source="descripcionLarga" />
            <ReferenceInput label="Sub categoria" source="subcategoria.id" reference="subcategorias">
                <SelectInput optionText="nombre" />
            </ReferenceInput>
            <ArrayInput source="industria">
                <SimpleFormIterator>
                <ReferenceInput label="Industria" source="id" reference="industrias">
                    <SelectInput optionText="titulo" />
                </ReferenceInput>
                </SimpleFormIterator>
            </ArrayInput>
            <ReferenceInput label="Marca" source="marca.id" reference="marcas">
                <SelectInput optionText="nombre" />
            </ReferenceInput>
            <ImageInput source="imagen" >
                <ImageField source="path" title="titulo" />
            </ImageInput>
            <FileInput source="archivo" >
                <FileField source="path" titlte="titulo"/>
            </FileInput>
        </SimpleForm>
    </Edit>
);

export const ProductoCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="create">
            <TextInput source="titulo" />
            <TextInput source="descripcion" />
            <TextInput multiline source="descripcionLarga" />
            <ReferenceInput label="Sub categoria" source="subcategoria" reference="subcategorias">
                <SelectInput optionText="nombre" />
            </ReferenceInput>
            <ArrayInput source="industria">
                <SimpleFormIterator>
                <ReferenceInput label="Industria" source="id" reference="industrias">
                    <SelectInput optionText="titulo" />
                </ReferenceInput>
                </SimpleFormIterator>
            </ArrayInput>
            <ReferenceInput label="Marca" source="marca" reference="marcas">
                <SelectInput optionText="nombre" />
            </ReferenceInput>
            <ImageInput source="imagen" >
                <ImageField source="path" titlte="titulo"/>
            </ImageInput>
            <FileInput source="archivo" >
                <FileField source="path" titlte="titulo"/>
            </FileInput>
         </SimpleForm>   
    </Create>
)
