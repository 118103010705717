import React, { useEffect, useState } from "react";
import Layout from '../layouts/Layout'
import '../styles/home.css'
import Carousel from '../components/Carousel'
import Banner1 from '../static/images/Banner Home 1.png'
import fotoHome from '../static/images/foto-home.png'

import Banner2 from '../static/images/Banner Home 2.png'
import Banner3 from '../static/images/Banner Home 3.png'
import Marcas from "../models/marcas";
import MultiCarousel from '../components/Multi-Carousel'

import Alert from '../components/Alert'
import Industrias from '../models/industrias'

function Home() {
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear"
    }
    
    const imagenesCarousel = [Banner1, Banner2, Banner3]
    const [marcasCarousel, setMarcasCarousel] = useState([])
    const [industriasCarousel, setIndustriasCarousel] = useState([])

    const getIndustrias = () => {
        const repo = new Industrias()
        repo.getIndustrias()
            .then(data => {
                setIndustriasCarousel(data && data.map(d=> ({imagen: d.imagen.path, texto: d.titulo})))
            })
    }

    useEffect(() => {
        const repo = new Marcas();
        repo.getMarcas()
            .then(data => {
                setMarcasCarousel(data && data.map(d => ({imagen: d.imagen.path})))
            });
        getIndustrias()

    }, [])

    return(
        <Layout>
            <section>
                <Carousel imagenes={imagenesCarousel} />
            </section>
            <br />
            <div className="container" style={{zIndex:1000}}>
                <div className="text-center jumbotron" style={{background:"#e9ecef"}}>
                    <h1 ><span className="">Termometria Argentina</span></h1><br/>
                    <p style={{fontWeight:400,fontSize:"22px"}}>
                        Desde 1978 Termometria Argentina ofrece a la industria y al agro soluciones tecnológicas de alta calidad, basadas en la experiencia de nuestro personal técnico y un amplio catalogo de marcas y productos.<br />
                        Orientamos a nuestros clientes en la compra (o elección) de los productos más  adecuados para cada una de sus necesidades, brindándoles un valorable asesoramiento en su toma de sus decisiones.
                        </p>
                        <br />
                        <img width={400} src={fotoHome} alt=''/>
                </div>
                <br />
                <section className="text-center mt-5">
                    <div className="text-center h1">
                        <hr />
                        <h1>Industrias</h1>
                        <hr />
                    </div>
                <br/>
                    {
                        industriasCarousel ?
                        <MultiCarousel imagenes={industriasCarousel} settings={{
                            dots: true,
                            arrows:true,
                            infinite: true,
                            slidesToShow: 4,
                            rows: 2,
                            slidesToScroll: 1,
                            autoplay: true,
                            speed: 2000,
                            autoplaySpeed: 100,
                            cssEase: "linear"
                        }} /> :
                        <Alert mensaje={"Estamos pasando por problemas tecnicos. Intente nuevamente mas tarde."} />
                    }
                </section>
                <hr/>
                <br/>
                <br/>
                <div className="row text-center mx-0" style={{justifyContent:'space-around'}}>
                    <div>
                        <div className="card mb-3" style={{width: "18rem", height:'321.8px', background:"#2196f3"}}>
                            <div  className="text-center" style={{backgroundColor: "rgb(214 220 222)",borderRadius:50,marginRight:'38%',marginLeft:'38%',marginTop:'10%'}}>
                                <i className="fas fa-box-open" style={{fontSize:45,height: 70,paddingTop: 10}} src="..." alt="Card image cap" />
                            </div>
                            <div className="card-body">
                                <h3 className=" text-white" style={{fontSize:'26px'}}>Productos</h3>
                                <p className="text-white card-text" style={{fontSize:'16px'}}>
                                    Comercializamos marcas renombradas a nivel internacional y 
                                    alternativas de excelente relación costo/beneficio para ajustarse a todas las necesidades del mercado.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="card mb-3" style={{width: "18rem", height:'321.8px', background:"#2196f3"}}>
                            <div  className="text-center" style={{backgroundColor: "rgb(214 220 222)",borderRadius:50,marginRight:'38%',marginLeft:'38%',marginTop:'10%'}}>
                                <i className="fas fa-tasks" style={{fontSize:45,height: 70,paddingTop: 10}} src="..." alt="Card image cap" />
                            </div>
                            <div className="card-body">
                                <h3 className="text-white" style={{fontSize:'26px'}}>Proyectos</h3>
                                <p className="card-text text-white" style={{fontSize:'16px'}}>
                                    Acompañamos con nuestro asesoramiento técnico, programación y configuración de equipos, 
                                    en la medida que nuestros clientes lo requieran.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="card" style={{width: "18rem", height:'321.8px',  background:"#2196f3"}}>
                            <div  className="text-center" style={{backgroundColor: "rgb(214 220 222)",borderRadius:50,marginRight:'38%',marginLeft:'38%',marginTop:'10%'}}>
                                <i className="fas fa-tools" style={{fontSize:45,height: 70,paddingTop: 10}} src="..." alt="Card image cap" />
                            </div>
                            <div className="card-body">
                                <h3 className="text-white" style={{fontSize:'26px'}}>Servicios</h3>
                                <p className="card-text text-white" style={{fontSize:'16px'}}>Contamos con laboratorio propio para calibraciones de temperatura y gases, y todas las variables que la industria requiere. </p>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="text-center mt-5">
                    <div className="text-center h1">
                        <hr />
                        <h1>Marcas</h1>
                        <hr />
                    </div>
                <br/>
                    {
                        marcasCarousel ?
                        <MultiCarousel imagenes={marcasCarousel} settings={settings} /> :
                        <Alert mensaje={"Estamos pasando por problemas tecnicos. Intente nuevamente mas tarde."} />
                    }
                </section>
            </div>
        </Layout>
    )
}
export default Home