import * as React from 'react'
import { 
    List, 
    Datagrid, 
    TextField, 
    EditButton, 
    Edit, 
    SimpleForm,
    TextInput,
    Create,
    Filter,
    ImageField,
    ImageInput,
} from 'react-admin'

const IndustriaFilter = props => (
    <Filter {...props} >
        <TextInput label="Search by titulo" source="titulo" alwaysOn />
    </Filter>
)

export const IndustriaList =  props => (
    <List filters={<IndustriaFilter />} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="titulo" />
            <TextField source="descripcion" />
            <EditButton />
        </Datagrid>
    </List>
)

const IndustriaTitle = ({ record }) => {
    return <span>
        {record ? `${record.titulo}` : ''}
    </span>
}

export const IndustriaEdit = props => (
    <Edit title={<IndustriaTitle />} {...props} undoable={false}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <TextInput source="titulo" />
            <TextInput source="descripcion" />
            <ImageInput source="imagen" >
                <ImageField source="path" title="titulo" />
            </ImageInput>
        </SimpleForm>
    </Edit>
);

export const IndustriaCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="titulo" />
            <TextInput source="descripcion" />
            <ImageInput source="imagen" >
                <ImageField source="path" titlte="titulo"/>
            </ImageInput>
         </SimpleForm>   
    </Create>
)

