import React from 'react'


function Carousel ({imagenes}) {

    const filterFirst= ()=>{ 
            imagenes && imagenes[1] && imagenes.shift()
    }
    return(

    <div id="carouselExampleIndicators" className="carousel slide mb-5" data-ride="carousel">
        <ol className="carousel-indicators">
            <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
        </ol>
        <div className="carousel-inner">
             <div className="carousel-item active" >
                <img src={imagenes && imagenes[0]} className="d-block w-100 imagen-carousel"  alt="..."/>
             </div>
            { filterFirst() }
            { imagenes && imagenes.map(i => (
                <div className="carousel-item" >
                    <img src={i} className="d-block w-100 imagen-carousel"  alt="..."/>
                </div>
            )) }
        </div>
        <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="sr-only">Previous</span>
        </a>
        <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="sr-only">Next</span>
        </a>
    </div>
    )
}
export default Carousel