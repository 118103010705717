import * as React from 'react'
import { 
    List, 
    Datagrid, 
    TextField, 
    EditButton, 
    Edit, 
    SimpleForm,
    TextInput,
    Create,
    Filter,
    ImageField,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    ImageInput,
} from 'react-admin'

const SubCategoriaFilter = props => (
    <Filter {...props} >
        <TextInput label="Search by nombre" source="nombre" alwaysOn />
    </Filter>
)

export const SubCategoriaList =  props => (
    <List filters={<SubCategoriaFilter />} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="nombre" />
            <ReferenceField label="Categoria" source="categoria.id" reference="categorias">
                <TextField source="titulo" />
            </ReferenceField>
            <EditButton />
        </Datagrid>
    </List>
)

const SubCategoriaTitle = ({ record }) => {
    return <span>
        {record ? `${record.nombre}` : ''}
    </span>
}

export const SubCategoriaEdit = props => (
    <Edit title={<SubCategoriaTitle />} {...props} undoable={false}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <TextInput source="nombre" />
            <ReferenceInput label="Categoria" source="categoria.id" reference="categorias">
                <SelectInput optionText="titulo" />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);

export const SubCategoriaCreate = props => (
    <Create {...props} >
        <SimpleForm redirect="create">
            <TextInput source="nombre" />
            <ReferenceInput label="Categoria" source="categoria" reference="categorias">
                <SelectInput optionText="titulo" />
            </ReferenceInput>
         </SimpleForm>   
    </Create>
)

