import React from 'react'
import Layout from '../layouts/Layout'
import Carousel from '../components/Carousel'
import Empresa1 from '../static/images/Foto Empresa1.png'
import Empresa2 from '../static/images/Foto Empresa2.png'
import Confianza from '../static/images/confianza.png'
import Politica from '../static/Politica de calidad 2019.pdf'
import Dnv from '../static/Certificado DNV V 2022.pdf'
import banner3 from '../static/images/logo termo pared.png'

function Empresa() {

    const imagenes = () => {
        return [Empresa1, Empresa2]
    }

    return (
        <Layout>
            <img src={banner3} className="card-img-top " />
            <div className="container">
                <div className="text-center h1">
                    <hr />
                    <h1>Empresa</h1>
                    <hr />
                </div>
                <br />
                <div className="" >
                    <div className="row">
                        <div className="col-lg-4 col-sm-12">
                            <Carousel imagenes={imagenes()}/>
                        </div>
                        <div className="col-lg-8 col-sm-12">
                            <div className="row">
                                <p style={{fontSize:"19px"}}>
                                    Proveemos a la industria Productos, Asistencia en Proyectos y Servicios de alta calidad y precios competitivos. 
                                    Todos nuestros procesos cuentan con certificación ISO 9001. 
                                </p>
                                <p style={{fontSize:"19px"}}>
                                    Disponemos de Laboratorio propio para calibraciones de Gases, Temperatura y otras variables presentes en la industria, con personal 
                                    técnico calificado y entrenado en las marcas comercializadas.
                                </p>
                                <p style={{fontSize:"19px"}}>
                                    Nuestro sector técnico brinda Asesoramiento, Instalación, Puesta en Marcha, Reparaciones y Calibraciones en 
                                    productos e integración de Sistemas de Automatizacion.
                                </p>
                            </div>
                            <h3 className=" row"><span  className=" "> Nuestras Fortalezas</span> </h3>
                            <div className="row">
                                <div className=" "><i style={{color:"#6ec1e3",fontSize:"19px"}}className="fa fa-check" /><span style={{fontSize:"18px"}} className=" "> Honestidad en nuestro accionar comercial.</span></div>
                                <div className="mt-2"><i style={{color:"#6ec1e3",fontSize:"19px"}}className="fa fa-check" /><span style={{fontSize:"18px"}} className=" "> Compromiso, profesionalismo y responsabilidad con nuestros clientes.</span></div>
                                <div className="mt-2 "><i style={{color:"#6ec1e3",fontSize:"19px"}}className="fa fa-check" /><span style={{fontSize:"18px"}} className=" "> Capacitación continua de nuestro equipo de trabajo.</span></div>
                                <div className="mt-2 "><i style={{color:"#6ec1e3",fontSize:"19px"}}className="fa fa-check" /><span style={{fontSize:"18px"}} className=" "> Puntualidad en las entregas, Rapidez en las repuestas y Calidad en los servicios.</span></div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />

                <div className="text-center">
                    <h2>Nuestra Historia</h2>
                    <p style={{fontSize:"18px"}}>Termometria Argentina representa la evolución de la empresa fundada por el LIc. en
                        Física T.R Suguer hace más de 40 años.<br/>
                        Desde sus inicios Termometria supo observar y escuchar al mercado y así interpretar
                        sus constantes cambios y requerimientos.<br/>
                        Hoy en día nos caracterizamos por ser un proveedor confiable y ofrecer a nuestros
                        clientes productos y soluciones innovadoras, satisfaciendo cada requerimiento.
                    </p>

                </div>
                <br />
                <hr />
                <br />
                <div className="text-center row">
                    <div className="col border-right">
                    <h2 className>Politica de calidad</h2>
                    <p style={{fontSize:"18px"}}>Desde 2013 Termometria Argentina trabaja con Sistema de Gestion de Calidad ISO
                        9001 certificado DNV -GL.<br/>
                        Nuestro SGC nos permite analizar e identificar riesgos, desviaciones, no
                        conformidades y además oportunidades de optimizar procesos, logrando resultados
                        acordes a los objetivos planteados y una mejora continua en nuestra gestión.<br/>
                    </p>
                    <a class="font-weight-bold h4" href={Politica}><i class="fa fa-download"></i>Politica de calidad</a>
                    <a class="ml-5 font-weight-bold h4" href={Dnv}><i class="fa fa-download"></i>Certificado DNV</a>

                    </div>
                    <div className="col">
                    <h2>Equipo de trabajo</h2>
                    <p style={{fontSize:"18px"}}>Contamos con un equipo técnico y profesional, entrenado para brindar asistencia pre y
                        post venta y comprometido la elaboración de propuestas eficientes para la satisfacción
                        de los requerimeintos de nuestros clientes.<br/>
                        Su constante capacitación garantiza respuestas técnicas calificadas y ágiles.<br/>
                    </p>
                    </div>
                </div>
                <br />
                <hr />
                <br />
                <div className="text-center">
                    <h2>Razones para confiar</h2>
                    <div className="row">
                        <img className="img-fluid" src={Confianza}/>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Empresa