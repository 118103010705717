import React from 'react'
import { Link } from 'react-router-dom'
import '../styles/industriaCard.css'

const IndustriaCard = ({industria}) => {

    return (
        <Link to={`/categorias/industria/${industria.id}`}>

                <div className="card h-100">
                    <img className="card-img-top img-fluid" src={ industria.imagen && industria.imagen.path} alt={industria.titulo}/>
                    <div className="card-body">
                        <h3 className="card-title h3"> { industria.titulo} </h3>
                        <p className="card-text" style={{textAlign:'center'}}> {( industria.descripcion.length>50 ? industria.descripcion.substring(0,47)+"...":industria.descripcion)}</p>

                    </div>
                </div>

        </Link>
    )
}

export default IndustriaCard