import { url } from '../constants/api'

export default class Categorias{

    #idCategoria = ''
    #titulo
    #descripcion
    #imagen = ''

    constructor(idCategoria = '', titulo = '', descripcion = '', imagen = '') {
        this.#idCategoria = idCategoria
        this.#titulo = titulo
        this.#descripcion = descripcion 
        this.#imagen = imagen
    }


    idCategoria() { return this.#idCategoria }
    titulo() { return this.#titulo }
    descripcion() { return this.#descripcion }
    imagen() { return this.#imagen }


    getCategorias() {
        return fetch(`${url}/categorias`, {
            method: 'GET'
        })
        .then(response => {return response.json()})
        .catch(error => console.log(error))
    }
    
    getCategoriasByIndustria(industria_id) {
        return fetch(`${url}/categorias/industria/${industria_id}`, {
            method: 'GET'
        })
        .then(response => {return response.json()})
        .catch(error => console.log(error))
    }
}