import { url } from '../constants/api'
export default class SubCategoria{

    getSubcategoriasByCategoria(categoria_id) {
        return fetch(`${url}/subcategorias/categoria/${categoria_id}`, {
            method: 'GET'
        })
        .then(response => {return response.json()})
        .catch(error => console.log(error))
    }
    getSubcategoria(subcategoria_id) {
        return fetch(`${url}/subcategorias/${subcategoria_id}`, {
            method: 'GET'
        })
        .then(response => {return response.json()})
        .catch(error => console.log(error))
    }
}