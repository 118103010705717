import React, {useEffect, useState} from 'react'
import '../styles/industriaCard.css'
import Productos from '../models/productos'
import ProductDetail from '../components/ProductDetail';

function ProductoView (props) {
    
  const [producto, setProducto] = useState(null)

  const init = idProducto => {
    const repo = new Productos()
    repo.getProducto(idProducto)
        .then(data => {
            setProducto(data)
          })
  }

    useEffect(() => {
        const idProducto = props.match.params.idProducto
        init(idProducto)
      }, [props])
      
      
      return(  producto!==null && <ProductDetail producto={producto} />
       
    )
}
export default ProductoView