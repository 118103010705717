import React, { useEffect, useState } from "react";
import Layout from '../layouts/Layout'
import IndustriaCard from '../components/IndustriaCard'
import Industrias from '../models/industrias'
import '../styles/industrias.css'
import Alert from '../components/Alert'
import banner2 from '../static/images/banner2.jpg'


function IndustriasList() {
    const [industrias, setIndustrias] = useState([]);
    const [error, setError] = useState([]);

    const getIndustrias = () => {
        const repo = new Industrias()
        repo.getIndustrias()
            .then(data => {
                setIndustrias(data)
            })
    }
        
    useEffect(() => {
        getIndustrias();
    }, []);

    return(
        <Layout>
            <img src={banner2} className="card-img-top " />
            <div className="mx-auto container">
                <div className="text-center h1">
                    <hr />
                    <h1>Industrias</h1>
                    <hr />
                </div>
                <div className="ml-3 mb-5">
                    <p className="texto-gris">
                        Atendemos las industrias más variadas ofreciendo principalmente productos para medición y
                        control de Temperatura, detección y análisis de Gases, Instrumental de Medicion / Comparación /
                        Calibración y equipos para Automatización como PLCs .HMI, Computadoras Industriales, Servos,
                        Variadores y plataforma de Internet de las Cosas
                        En el área de Servicios, ofrecemos calibración de Temperatura y Gases en nuestro propio
                        laboratorio, y proyectos de programación e integración de PLCs.</p>
                </div>
            </div>
            <div className="container text-center">
                <div className="row">
                    {
                        industrias ? 
                        industrias.map((ind) => (
                            <div key={ind.id} className="col-md-4 col-12 mb-3">
                                <IndustriaCard industria={ind} />
                            </div>
                        )) :
                        <Alert mensaje={"Estamos pasando por problemas tecnicos. Intente nuevamente mas tarde."} />
                    }
                </div>
                    
            </div>
        </Layout>
    )
}
export default IndustriasList