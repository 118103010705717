
import React from 'react'
import { Link } from 'react-router-dom'
import '../styles/industriaCard.css'

const ServicioComponent = (props) => {

    return (
            <div class="row">
                <div class="col-8">
                    <h3>{props.titulo}</h3>
                    <p>{props.texto}</p>
                </div>
                <div class="col-4"><img src={props.imagen} /></div>
            </div>
    )
}

export default ServicioComponent