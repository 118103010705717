import React from 'react'
import Layout from '../layouts/Layout'
import iso from '../static/images/iso.png'
import Servicio1 from '../static/images/Servicio1.png'
import Servicio2 from '../static/images/Servicio2.png'
import Servicio3 from '../static/images/Servicio3.png'
import Servicio4 from '../static/images/Servicio4.png'
import Servicio5 from '../static/images/Servicio5.png'
import Servicio6 from '../static/images/Servicio6.png'
import Servicio7 from '../static/images/Servicio7.png'
import NuestroLab from '../static/images/NuestroLab.png'
import Medidor from '../static/images/medidor.png'
import Nube from '../static/images/computacion-en-la-nube.png'
import Termometro from '../static/images/termometro.png'


import ServicioComponent from '../components/ServicioComponent'


function Servicios() {
    return (
        <Layout>
            <div className="container ">
                <div className="text-center h1">
                    <hr />
                    <h1>Servicios</h1>
                    <hr />
                </div>
                <br />
                <h2 class="text-center mb-3">Nuestro Laboratorio</h2>
                <div class="row">
                    <div class="col">
                        <img src={NuestroLab} />
                    </div>
                    <div class="col">
                    <p style={{fontSize:"18px"}}>Realizamos calibracion de instrumental de medición de:</p>
                    <img src={Termometro} height="40"/><strong  style={{fontSize:"18px"}}className="h5"> Temperatura</strong><br/><br/>
                    <img src={Nube} height="40"/><strong style={{fontSize:"18px"}} className="h5"> Gases</strong><br/><br/>
                    <img src={Medidor} height="40"/><strong  style={{fontSize:"18px"}} className="h5"> Otras variables físicas</strong><br/><br/>
    
                    <i className="fa fa-check-circle" /><span style={{fontSize:"18px"}}>  Contamos con Patrones nacionales  trazables a INTI e internacionales al NIST y PTB</span><br/>
                    <i className="fa fa-check-circle" /><span style={{fontSize:"18px"}}>  Trabajamos bajo  Norma ISO 9001 para todos los procesos de calibración.<br/></span >
                    <i className="fa fa-check-circle" /><span style={{fontSize:"18px"}}>  Nuestro  Sistema de Archivo permite el seguimiento del instrumental ingresado al laboratorio en cualquier momento que el cliente lo requiera.</span ><br/>
                    <i className="fa fa-check-circle" /><span style={{fontSize:"18px"}}>  Pertenecemos a la red de Servicios Entrenados Zonales (ZES) de MSA </span >

                    </div>
                </div>
                                      <br/>
                    <br/>
                <div id="accordion">
                    <div className="row container">
                        <a> <h3 className=""  data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" className="">Mantenimiento de Detectores de Gases</h3></a>
                        <div id="collapseOne" className="collapse w-100" data-parent="#accordion">
                            <div className="row">
                                <div  className="col-md-8 col-sm-12"  >
                                    <p style={{fontSize:"18px"}}>Desarrollamos servicios preventivos de mantenimiento, basado en la programación de visitas técnicas al sitio -trimestral o semestralmente- para la calibración, ajuste y recambio de repuestos necesarios.</p>
                                </div>
                                <div className="col-md-4 col-sm-12 my-auto"><img height="200" className="float-right" src={Servicio4} /></div>
                            </div>
                        </div>
                    </div>
                    <hr />

                    <div className="row container">
                        <a> <h3 className=""  id="headingOne" data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapse2" className="">Mapeo Térmico</h3></a>
                        <div id="collapse2" className="collapse w-100" data-parent="#accordion">
                            <div className="row">
                                <div  className="col-md-8 col-sm-12"  >
                                    <p style={{fontSize:"18px"}}>Nuestro servicio de mapeo térmico permite conocer la distribución de la temperatura en diferentes zonas de un espacio crítico.</p>
                                    <i className="fa fa-check-circle" /> Colocación en puntos estratégicos de Dataloggers que registran la temperatura en un lapso establecido, 2, 7 o 15 días.<br />
                                    <i className="fa fa-check-circle" /> Entregamos a nuestro cliente un informe final detallado y un diagnostico de la performance de la temperatura. <br />
                                    <i className="fa fa-check-circle" /> Nuestros patrones estan certificados con trazabilidad a INTI, NIST y PTB Alemania.<br />
                                    <i className="fa fa-check-circle" /> Realizamos estudios de perfiles térmicos en:Depósitos, almacenes, cámaras, transporte, heladeras.
                                </div>
                                <div className="col-md-4 col-sm-12 my-auto"><img height="200" className="float-right" src={Servicio6} /></div>
                            </div>
                        </div>
                    </div>
                    <hr />

                    <div className="row container">
                        <a> <h3 className=""  data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapse3" className="">Programacion de PLC</h3></a>
                        <div id="collapse3" className="collapse w-100" data-parent="#accordion">
                            <div className="row">
                                <div  className="col-md-8 col-sm-12"  >
                                <p style={{fontSize:"18px"}}>Ofrecemos el servicio de programación de PLCs marca Wecon, asi como su integración con otros productos Wecon (HMI, Internet de las Cosas, Variadores de Frecuencia, Servos), o bien la integración de productos Wecon a sistemas de automatización que pudieran incluir otras marcas. Ofrecemos adicionalmente la programación de las pantallas de Interfase Humano Maquina (HMI), y configuración del Gateway de Internet de las Cosas. Podemos asistir a nuestros clientes en la Puesta en Marcha, y en eventuales futuras actualizaciones de los programas.</p>
                                </div>
                                <div className="col-md-4 col-sm-12 my-auto"><img height="200" className="float-right" src={Servicio7} /></div>
                            </div>
                        </div>
                    </div>
                    <hr />

                    <div className="row container">
                        <a> <h3 className=""  data-toggle="collapse" data-target="#collapse4" aria-expanded="true" aria-controls="collapse4" className="">Inspeccion de Aparatos Sometidos a Presión</h3></a>
                        <div id="collapse4" className="collapse w-100" data-parent="#accordion">
                            <div className="row">
                                <div  className="col-md-8 col-sm-12"  >
                                    <p style={{fontSize:"18px"}}>Ofrecemos ensayos de Inspección legal, Habilitación y Extensión de Vida útil.<br />Entre otros servicios ofrecemos:</p>
                                    <ul>
                                        <div className="row">
                                            <div className="col">
                                                <li>Medición de espesores por ultrasonido</li>
                                                <li>Dureza de materiales</li>
                                                <li>Ensayo de líquidos penetrantes</li>
                                                <li>Partículas magnéticas</li>
                                                <li>Inspección interior por fibroscopia</li>
                                            </div>
                                            <div className="col">
                                                <li>Emisión acústica</li>
                                                <li>Pruebas hidráulicas</li>
                                                <li>Réplicas metalográficas</li>
                                                <li>Medición de gases de combustión</li>
                                                <li>Presentaciones y calibraciones según OPDS</li>
                                            </div>
                                        </div>
                                    </ul>                                    </div>
                                    <div className="col-md-4 col-sm-12 my-auto"><img height="200" className="float-right" src={Servicio1} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />

                    <div className="row container">
                        <a> <h3 className=""  data-toggle="collapse" data-target="#collapse5" aria-expanded="true" aria-controls="collapse5" className="">Válvulas de Seguridad y Alivio – Calibracion OPDS</h3></a>
                        <div id="collapse5" className="collapse w-100" data-parent="#accordion">
                            <div className="row">
                                <div  className="col-md-8 col-sm-12"  >
                                <p style={{fontSize:"18px"}}>Ofrecemos un servicio de calibración en el cual se fija una presión de acuerdo con la necesidad del equipo o la solicitud del cliente.El servicio se puede realizar en las instalaciones del cliente o en nuestro taller/ laboratorio.</p>
                                <ul>
                                    <div className="row">
                                        <div className="col">
                                            <li>Válvulas de seguridad, en un rango de 1 a 100 Kg/cm2, desde 1/4", roscadas o bridadas sin importar el material.</li>
                                            <li>Colocación de válvula en banco, verificación de apertura</li>
                                            <li>Desarme total</li>
                                            <li>Verificación de piezas y resorte</li>
                                        </div>
                                        <div className="col">
                                            <li>Arenado de partes para pintar</li>
                                            <li>Armado y montaje nuevamente en banco</li>
                                            <li>Regulación de válvula a presión solicitada</li>
                                            <li>Prueba de apertura y cierre</li>
                                            <li>Ensayo de estanqueidad</li>
                                            <li>Pintado</li>
                                            <li>Precintado y entrega</li>
                                        </div>
                                    </div>
                                </ul>
                                </div>
                                    <div className="col-md-4 col-sm-12 my-auto"><img height="200" className="float-right" src={Servicio5} /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />

                    <div className="row container">
                        <a> <h3 className=""  data-toggle="collapse" data-target="#collapse7" aria-expanded="true" aria-controls="collapse7" className="">Adquisición de Datos Inalámbricos</h3></a>
                        <div id="collapse7" className="collapse w-100" data-parent="#accordion">
                            <div className="row">
                                <div className="col-md-8 col-sm-12"  >
                                <p style={{fontSize:"18px"}}>Además de proveer los materiales podemos implementar redes de sensores inalámbricos para aplicaciones especiales. Estos datos usualmente se suben a alguna plataforma en la nube para su posterior acceso remoto por computadoras o dispositivos móviles.</p>
                                </div>
                            <div className="col-md-4 col-sm-12 my-auto"><img height="200" className="float-right" src={Servicio2} /></div>
                            </div>
                        </div>
                    </div>
                    <hr />

                    <div className="row container">
                        <a> <h3 className=""  data-toggle="collapse" data-target="#collapse8" aria-expanded="true" aria-controls="collapse8" className="">Cursos de Capacitación</h3></a>
                        <div id="collapse8" className="collapse w-100" data-parent="#accordion">
                            <div className="row">
                                <div className="col-md-8 col-sm-12"  >
                                <p style={{fontSize:"18px"}}>Se dictan cursos sobre el uso correcto de todos los productos que comercializamos y sus aplicaciones. Estos cursos se pueden dictar en nuestras instalaciones, las del cliente o en forma remota por plataformas en internet, en la modalidad “a distancia”.</p>
                                </div>
                        <div className="col-md-4 col-sm-12 my-auto"><img height="200" className="float-right" src={Servicio3} /></div>
                            </div>
                        </div>
                    </div>
                    <hr />
            </div> 
        </Layout>
    )
}

export default Servicios